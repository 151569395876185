import Link from "next/link";

export default function ContactUsSection() {
  return (
    <section
      id="section-call-to-action"
      className="bg-color-2 pt60 pb60 text-light"
    >
      <div className="container">
        <div className="row align-items-center">
          <div className="col-lg-8">
            <h2 className="s2 contact-title">
              {" "}
              Call us for further information. MT EVEREST LIMO LLC
              care is here to help you anytime.
            </h2>
          </div>

          <div className="col-lg-4 text-lg-center text-sm-center contact-content">
            <div className="phone-num-big">
              <i className="fa fa-phone"></i>
              <span className="pnb-text">Call Us Now</span>
              <span className="pnb-num">+14153092068</span>
            </div>

            <Link className="btn-main" href={"/contact"}>
              Contact Us
            </Link>
          </div>
        </div>
      </div>
    </section>
  );
}
