type ServiceCardProps = {
  title: String;
  description: String;
};

export default function ServiceCard({ title, description }: ServiceCardProps) {
  return (
    <div className="services col-md-3">
      <i className="fa fa-user de-icon mb20"></i>
      <h4>{title}</h4>
      <p>{description}</p>
    </div>
  );
}
