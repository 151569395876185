import Image from "next/image";

export default function UserReviewCard() {
  return (
    <div className="col-lg-4 col-md-6 mb10">
      <div className="bloglist s2 item">
        <div className="post-content">
          <div className="post-image">
            <div className="post-title">
              <h4>
                <a href="#">
                  Nataliya Tkachova<span></span>
                </a>
              </h4>
              <p>2022-01-17</p>
              <i className="fa fa-star post-icon"></i>
              <i className="fa fa-star post-icon"></i>
              <i className="fa fa-star post-icon"></i>
              <i className="fa fa-star post-icon"></i>
            </div>

            <div className="date-box">
              <Image
                height={100}
                width={100}
                alt=""
                src="/images/person/girl.avif"
                className="lazy"
                style={{ objectFit: "cover" }}
              />
            </div>

            {/* <!-- <img alt="" src="images/person/girl.avif" className="lazy" height="173px" width="409px" style="object-fit: cover;"> --> */}
          </div>
          <div className="post-text">
            <h4>
              <a href="#">
                Nataliya Tkachova<span></span>
              </a>
            </h4>
            <p>
              Booking and transfer were very efficient, very professional, and
              very friendly. A highly recommendable no-hassle way of getting
              anywhere In Chicagoland and for
            </p>
            <a className="btn-main" href="#">
              Read More
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}
