import { Fleet } from '@/types/model.types';
import Image from 'next/image';
import Link from 'next/link';

type FleetCardProps = {
  fleet: Fleet;
  isSlider?: boolean
};



export default function FleetCard({
  fleet: {
    title,
    passenger_limit,
    interior_type,
    heated_seats,
    category,
    slug,
    images,
    // fleet_rate,
  },
  isSlider = false
}: FleetCardProps) {
  const primaryImage = images && images.length > 0 ? images[0] : null;
  return (
    <div className={isSlider ? '' : 'col-lg-4 col-md-6'}>
      <div className='de-item mb30'>
        <div className='d-img image-placeholder'>
          {primaryImage && (
            <Image
              width={387}
              height={217}
              src={`${process.env.NEXT_PUBLIC_DO_CDN}/${primaryImage.image}`}
              className='img-fluid fleet-image'
              alt={title}
            />
          )}
        </div>
        <div className='d-info'>
          <div className='d-text'>
            <h3>{title}</h3>
            <div className='d-atr-group'>
              {passenger_limit && (
                <span className='d-atr'>
                  <Image
                    width={100}
                    height={100}
                    src='/images/icons/1.svg'
                    className='img-fluid'
                    alt=''
                  />
                  {passenger_limit} Passengers
                </span>
              )}
              {interior_type && (
                <span className='d-atr'>
                  <Image
                    width={100}
                    height={100}
                    src='/images/icons/2.svg'
                    className='img-fluid'
                    alt=''
                  />
                  {interior_type}
                </span>
              )}
              {heated_seats && (
                <span className='d-atr'>
                  <Image
                    width={100}
                    height={100}
                    src='/images/icons/3.svg'
                    className='img-fluid'
                    alt=''
                  />
                  Heated Seats
                </span>
              )}
              <span className='d-atr'>
                <Image
                  width={100}
                  height={100}
                  src='/images/icons/4.svg'
                  className='img-fluid'
                  alt=''
                />
                {category?.title}
              </span>
            </div>
            <div className='d-price'>
              {/* Hourly rate{' '} */}
              {/* <span>{fleet_rate ? '$' + fleet_rate.per_hour : '-'}</span> */}
              <Link
                className='btn-main'
                href={{
                  pathname: '/fleets/[slug]',
                  query: { slug },
                }}
              >
                View Details
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
