import ServiceCard from "@/comp/ServiceCard";
import UserReviewCard from "@/comp/UserReviewCard";
import ContactUsSection from "@/comp/ContactUsSection";
import FleetCard from "@/comp/fleet/FleetCard";
import Link from "next/link";
import { GetServerSidePropsContext, GetServerSidePropsResult } from "next";
import request from "@/utils/request.utils";
import { Category, Fleet } from "@/types/model.types";
import Head from "next/head";
import Slider from "react-slick";

type FleetDetailProps = {
  fleets?: {
    count: number;
    rows: Fleet[];
  };
  categories?: {
    count: number;
    rows: Category[];
  };
};
const settings = {
  dots: true,
  infinite: false,
  speed: 500,
  slidesToShow: 3,
  slidesToScroll: 1,
  initialSlide: 0,
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 1,
        infinite: true,
        dots: true,
      },
    },
    {
      breakpoint: 768,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1,
        initialSlide: 0,
      },
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
  ],
};
export default function Home({ fleets }: FleetDetailProps) {
  const filteredFleet: Fleet[] | undefined = fleets?.rows;
  return (
    <>
      <Head>
        <title>Mt Everest Limo</title>
        <link
          rel="stylesheet"
          type="text/css"
          // charset="UTF-8"
          href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.8.1/slick.min.css"
        />
        <link
          rel="stylesheet"
          type="text/css"
          href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.8.1/slick-theme.min.css"
        />
      </Head>
      <div className="no-bottom no-top" id="content">
        <section
          id="section-hero"
          aria-label="section"
          className="full-height vertical-center"
          style={{
            marginTop: -18,
            background: "url(images/background/9.png) bottom",
          }}
        >
          <div className="container">
            <div className="row align-items-center">
              <div className="spacer-double sm-hide"></div>
              <div className="col-lg-5">
                <h4>
                  <span className="id-color">Plan your trip now</span>
                </h4>
                <div className="spacer-10"></div>
                <h1 className="banner-main-title">
                  Discover unparalleled <br />
                  luxury and convenience
                </h1>
                <p className="lead">
                  We specialize in fleet bookings for airport transfers and
                  lavish journeys to elevate your travel experience.
                </p>
                <Link className="btn-main" href={"/reservations"}>
                  Request a quote
                </Link>
                &nbsp;&nbsp;&nbsp;
                <a href="tel:+14153092068" className="btn-main btn-black">
                  Call Us
                </a>
              </div>
            </div>
          </div>
        </section>

        <section id="section-cars" className="no-top car-container">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-lg-6 offset-lg-3 text-center">
                <h2>Our Fleet</h2>
                <p>
                  Explore our range of vehicles and enjoy a first-class ride
                  with Mt Everest Limo.
                </p>
                <div className="spacer-20"></div>
              </div>
            </div>

            <div className="clearfix"></div>

            {/* <div id='items-carousel' className=''> */}
            {/* <div className='row'> */}
            <Slider {...settings}>
              {filteredFleet?.map((fleet: any) => {
                return (
                  <FleetCard isSlider={true} fleet={fleet} key={fleet.title} />
                );
              })}
            </Slider>
            {/* </div> */}
            {/* </div> */}
          </div>
        </section>

        <section
          id="section-img-with-tab"
          data-bgcolor="#f8f8f8"
          style={{
            backgroundColor: "#f8f8f8",
          }}
        >
          <div className="container">
            <div className="row align-items-center">
              <div className="col-lg-5 col-md-12 offset-lg-7">
                <h2>We Promise, You Will Have The Best Experience</h2>
                <h5>
                  For the best Night party limo service just contact Mt Everest Limo
                  
                </h5>
                <div className="spacer-20"></div>

                <div className="tab-content" id="pills-tabContent">
                  <div
                    className="tab-pane fade show active"
                    id="pills-home"
                    role="tabpanel"
                    aria-labelledby="pills-home-tab"
                  >
                    <p>
                    At our Mt. Everest Limo, we&apos;re dedicated to delivering sophistication and excellence. 
                    From our well-maintained premium fleet to our courteous chauffeurs, 
                    your experience will be marked by elegance, comfort, and impeccable service. Whether it&apos;s a special occasion or a corporate event, we promise an extraordinary journey. 
                    Your satisfaction is our priority, and we&apos;re committed to exceeding your expectations.
                    </p>
                  </div>
                  <div
                    className="tab-pane fade"
                    id="pills-profile"
                    role="tabpanel"
                    aria-labelledby="pills-profile-tab"
                  >
                    <p>
                      Ad pariatur nostrud pariatur exercitation ipsum ipsum
                      culpa mollit commodo mollit ex. Aute sunt incididunt amet
                      commodo est sint nisi deserunt pariatur do. Aliquip ex
                      eiusmod voluptate exercitation cillum id incididunt elit
                      sunt. Qui minim sit magna Lorem id et dolore velit Lorem
                      amet exercitation duis deserunt. Anim id labore elit
                      adipisicing ut in id occaecat pariatur ut ullamco ea
                      tempor duis.
                    </p>
                  </div>
                  <div
                    className="tab-pane fade"
                    id="pills-contact"
                    role="tabpanel"
                    aria-labelledby="pills-contact-tab"
                  >
                    <p>
                      Est quis nulla laborum officia ad nisi ex nostrud culpa
                      Lorem excepteur aliquip dolor aliqua irure ex. Nulla ut
                      duis ipsum nisi elit fugiat commodo sunt reprehenderit
                      laborum veniam eu veniam. Eiusmod minim exercitation
                      fugiat irure ex labore incididunt do fugiat commodo
                      aliquip sit id deserunt reprehenderit aliquip nostrud.
                      Amet ex cupidatat excepteur aute veniam incididunt mollit
                      cupidatat esse irure officia elit do ipsum ullamco Lorem.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div
            style={{
              background: "url(images/background/13.jpeg) center",
            }}
            className="image-container col-md-6 pull-right"
            data-bgimage="url(images/background/13.jpeg) center"
          ></div>
        </section>

        <section
          className="text-light jarallax services-section"
          aria-label="section"
          style={{
            margin: "90px 0 50px",
            backgroundImage: "url(/images/background/3.jpg)",
            backgroundSize: "cover",
          }}
        >
          <div className="container">
            <div className="row">
              <div className="col-lg-3 col-md-12">
                <div className="services-title">
                  <h1>Here are the Services we provide.</h1>
                </div>
                <div className="spacer-20"></div>
              </div>
              <div className="services col-md-3">
                <i className="fa fa-plane de-icon mb20"></i>
                <h4>Airport Transfers</h4>
                <p>
                  Use airport shuttle service from Mt Everest Limo in CA,
                  and you’ll never have to break a sweat … or worry about
                  traffic, driving in bad weather, or the cost and hassle of
                  airport parking.
                </p>
              </div>

              <ServiceCard
                title={"Business Limo Services"}
                description={`Mt Everest Limo  gives our Corporate and Executive clients the
              best experience by providing a Corporate limo service in an
              upscale and timely manner. They will feel as though they are on
              top of the world because of the classiness of our Mt Everest Limos.`}
              />

              <ServiceCard
                title={"Point to Point"}
                description={`Where would you like to go, meeting or touring of CA City?
              Our professional chauffeurs can provide the luxury transportation
              that you deserve, with safe and reliable service.`}
              />
              <div className="col-md-3"></div>
              <div className="services col-md-3">
                <i className="fa fa-birthday-cake de-icon mb20"></i>
                <h4>Birthdays</h4>
                <p>
                  When a big birthday comes around, surprise your loved one with
                  a stretch Mt Everest Limo to celebrate the special moment. Imagine
                  how excited they’ll be when Mt Everest Limo  pulls up in a
                  limo bus with all their friends on board!
                </p>
              </div>
              <div className="services col-md-3">
                <i className="fa fa-glass de-icon mb20"></i>
                <h4>Proms</h4>
                <p>
                  Kids and parents might have different priorities when it comes
                  to prom limo rentals. Mt Everest Limo  is your experienced,
                  reliable partner who makes everyone happy in CA
                </p>
              </div>
              <div className="services col-md-3">
                <i className="fa fa-star de-icon mb20"></i>
                <h4>Night Party</h4>
                <p>
                  Night party limo service. For your night on the town you can
                  rely on Mt Everest Limo  to get you there in ultimate comfort,
                  style and safety.
                </p>
              </div>
            </div>
          </div>
        </section>

        {false && <section id="section-cars">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-lg-6 offset-lg-3 text-center">
                <h2>Our Ratings</h2>
                <p>
                  Sit labore et officia eiusmod tempor officia eiusmod dolor
                  exercitation nulla dolore ut id velit et ut anim.
                </p>
                <div className="spacer-20"></div>
              </div>

              <div>
                <div className="row">
                  <UserReviewCard />
                </div>
              </div>
            </div>
          </div>
        </section>}

        <ContactUsSection />
      </div>
    </>
  );
}

export async function getServerSideProps(
  context: GetServerSidePropsContext
): Promise<GetServerSidePropsResult<any>> {
  const fleets = await request.get(
    `/fleets?withCategory=true&withRates=true&withImages=true&catId=${
      context.query.cat ? parseInt(context.query.cat as string, 10) : ""
    }`
  );
  const categories = await request.get("/categories?onlyParent=true");
  return {
    props: {
      fleets: fleets.status === 200 ? fleets.data : [],
      categories: categories.status === 200 ? categories.data : [],
    },
  };
}

// export async function getGoogleRating(
// ): Promise<GetServerSidePropsResult<any>> {
//   const rating = await request.get('mybusiness.googleapis.com/v4/accounts/{AIzaSyCfd3TwXnERJGJAILkjhDjqRxccQndLbJI}/locations/{Od5hYAR7jo0nxcsOEw}/reviews');
//   console.log('rating', rating)
//   return {
//     props: {
//       rating: rating,
//     },
//   };
// }


